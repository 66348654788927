import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { IUser } from '../../model/user.model';
import { IDocument } from '../generic';

export interface IEventManager extends IDocument {
  user_id: string;
  event_id: string;
  user?: IUser;
}

export interface IInternalEventManager extends IDocument {
  authKey: string;
  authToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class EventManagerService {

  constructor(
    private afStore: AngularFirestore,
    private afAuth: AngularFireAuth
    ) { }

  getManagerProfile(eventId: string, isAdmin = false) {
  if(isAdmin) {
    return of(true);
  }
  if(!eventId) {
    return of(false);
  }
    return this.afAuth.authState.pipe(
      mergeMap((user) => {
        if(eventId && user)
        {
          return this.afStore.collection<IEventManager>(`eventmanager`, ref => ref.where('user_id','==',user.uid).where('event_id','==',eventId)).valueChanges({ idField: 'key' });
        }
        else
        {
          return of(null);
        }
      })
    )
    .pipe(
      mergeMap((eventManager: IEventManager[]) => {
        if(eventManager && eventManager.length > 0 && eventManager[0].user_id) {
          return of(true)
        }
        else {
          return of(false);
        }
      })
    );
  }

  getManagers(event_id: string) {
    return this.afStore.collection<IEventManager>('/eventmanager', ref => ref.where('event_id', '==', event_id)).valueChanges({ idField: 'key' });
  }

  getManager(id: string) {
    return this.afStore.doc<IEventManager>(`/eventmanager/${id}`).valueChanges({ idField: 'key' });
  }

  updateManager(eventmanager: IEventManager) {
    return this.afStore.doc<IEventManager>(`/eventmanager/${eventmanager.key}`).update({
    })
  }

  updateEventManager(eventId: string, userId: string, authKey: string, authToken: string) {
    return this.afStore.doc<IInternalEventManager>(`event/${eventId}/eventmanager/${userId}`).update({
      authKey,
      authToken
    })
  }

  getEventManager(eventId: string, userId: string) {
    return this.afStore.doc<IInternalEventManager>(`event/${eventId}/eventmanager/${userId}`).valueChanges({ idField: 'key' });
  }

  addManager(eventmanager: IEventManager) {
    return this.afStore.collection<IEventManager>(`/eventmanager`, ref =>
    ref
    .where('event_id', '==', eventmanager.event_id)
    .where('user_id', '==', eventmanager.user_id)).get().toPromise()
    .then((manager) => {
      if(manager.docs.length == 0) {
        return this.afStore.collection<IEventManager>(`/eventmanager`).add(eventmanager);
      }
      else {
        throw 'This user is already manager';
      }
    })
  }

  deleteManager(id: string) {
    return this.afStore.doc<IEventManager>(`/eventmanager/${id}`).delete();
  }
}
