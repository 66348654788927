import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'; // Optional if you are dealing with file URLs
import { StorageService } from '../../services/storage/storage.service';

@Component({
  selector: 'ngx-download-link',
  templateUrl: './download-link.component.html',
  styleUrls: ['./download-link.component.scss']
})
export class DownloadLinkComponent implements OnInit {
  @Input() value: string; // This will receive the row data (optional)
  @Input() rowData: any;  // This will receive the entire row's data

  constructor(private sanitizer: DomSanitizer, private storageService: StorageService) {}

  ngOnInit(): void {
  }

  downloadFile(): void {
    console.log('File download triggered for:', this.rowData);

    console.log('rowData', this.rowData);

    this.storageService.getFileDownloadURL(`sepa/${this.rowData.file}`).then((downloadURL) => {
      this.triggerDownload(downloadURL);
    }).catch((error) => {
      console.log('file not found')
    })

    // OR, if you are downloading from the server, you could call a service to do this
    // this.myService.downloadFile(fileUrl).subscribe(...)
  }

  private triggerDownload(url: string) {
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop() || 'downloaded-file';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}