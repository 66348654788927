import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

export interface ISettings {
  algoliaAppId?: string;
  algoliaApiKey?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private afStore: AngularFirestore
  ) { }

  getSettings(type: string) {
    return this.afStore.doc<ISettings>(`/settings/${type}`).valueChanges();
  }
}

