import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { EventService } from './services/database/event/event.service';
import { EventManagerService } from './services/database/event/eventmanager.service';
import { GlobalConstants } from './services/global-constants';

@Injectable()
export class ManagerGuard implements CanActivate {

  constructor(private router: Router,
    private eventManagerService: EventManagerService, private eventService: EventService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const eventId = this.eventService.getEventIdFromURL(state.url);
    return this.eventManagerService.getManagerProfile(eventId, GlobalConstants.flagAdmin)
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['manager']);
          }
        }),
      );
  }
}
