import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    private afStorage: AngularFireStorage
  ) { }

  getFileDownloadURL(fileName: string) {
    return this.afStorage.storage.ref(fileName).getDownloadURL();
  }

  uploadFile(eventId:string, fileName: string, data: any) {
    return this.afStorage.upload(`/uploads/${eventId}/${fileName}`, data, {contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
  }
}
