import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { mergeMap } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { IUser } from "../../model/user.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private afStore: AngularFirestore,
    private afAuth: AngularFireAuth,
    private afFunctions: AngularFireFunctions
  ) {}

  getCurrentUser(): Observable<IUser> {
    return this.afAuth.authState.pipe(
      mergeMap((user) => {
        if (user) {
          return this.afStore
            .doc<IUser>(`user/${user.uid}`)
            .valueChanges({ idField: "key" });
        } else {
          return of({
            flagAdmin: false,
            image: null,
            name: null,
            firstName: null,
            lastName: null,
            role: null,
            description: null,
            email: null,
            provider: null,
            phoneNumber: null,
            isShell: null,
            uid: null,
            language: null,
            qrcode: null,
            receiveTokens: null,
            acceptFriendRequest: null,
            newsletter: null,
            groupEnter: null,
            claim: null,
            adminReceives: null,
            eventInfo: null,
            contributionRequest: null
          });
        }
      })
    );
  }

  getCurrentUserPromise() {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          reject("No user logged in");
        }
      });
    });
  }

  updateUser(userId: string, value: Object) {
    return this.afStore.doc(`user/${userId}`).update(value);
  }

  createUser(firstName: string, lastName: string, qrcode: string, uid: string) {
    return this.afStore.doc(`user/${uid}`).set({
      firstName,
      lastName,
      qrcode,
      uid
    }, {merge: true})

  }

  deleteUser(uid: string) {
    return this.afStore.doc(`user/${uid}`).delete();
  }

  createUserFull(firstName: string, lastName: string, qrcode: string, uid: string, name: string, unread: number) {
    return this.afStore.doc(`user/${uid}`).set({
      firstName,
      lastName,
      uid,
      name,
      unread
    }, {merge: true})

  }

  createUserIfNotExists(user: IUser) {
    return this.afStore.doc(`user/${user.uid}`).ref.get().then((userDoc) => {
      if(!userDoc.exists) {
        return this.afStore.doc<IUser>(`user/${user.uid}`).set({
          uid: user.uid,
          image: user.image,
          name: user.name,
          firstName: user.firstName,
          lastName: user.lastName,
          description: user.description,
          provider: user.provider,
          phoneNumber: user.phoneNumber,
          email: user.email,
          language: user.language,
          isShell: user.isShell,
          receiveTokens: true,
          acceptFriendRequest: true,
          newsletter: true,
          groupEnter: true,
          claim: true,
          adminReceives: true,
          eventInfo: true,
          contributionRequest: true,
          flagAdmin: false
        })
      }
      return null;
    })
  }

  clearMyQRCode(userId: string) {
    return this.afStore.doc<IUser>(`user/${userId}`).update({
      qrcode: null
    })
  }

  getUser(id: string) {
    return this.afStore
      .doc<IUser>(`user/${id}`)
      .valueChanges({ idField: "key" });
  }

  getUserDocument(id: string) {
    return this.afStore
      .doc<IUser>(`user/${id}`).ref.get()
  }

  getUsersByName(searchValue: string) {
    const searchValueLowerCase = searchValue.toLowerCase();
    const firstName = this.afStore.collection<IUser>('user', ref => ref.orderBy('firstNameLowercase').startAt(searchValueLowerCase).endAt(searchValueLowerCase+'\uf8ff')).valueChanges({ idField: 'key' });
    //const lastName = this.afStore.collection<IUser>('user', ref => ref.orderBy('lastNameLowercase').startAt(searchValueLowerCase).endAt(searchValueLowerCase+'\uf8ff')).valueChanges({ idField: 'key' });
    //const fullName = this.afStore.collection<IUser>('user', ref => ref.orderBy('fullNameLowercase').startAt(searchValueLowerCase).endAt(searchValueLowerCase+'\uf8ff')).valueChanges({ idField: 'key' });
    return firstName;
  }

  exportUsers(eventId: string) {
    const callable = this.afFunctions.httpsCallable('exportUsers');
    return callable({eventId: eventId});
  }
}
