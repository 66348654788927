import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NbAuthToken } from '@nebular/auth';

import { UserService } from '../../../services/database/user/user.service';
import { IUser } from '../../../services/model/user.model';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { GlobalConstants } from '../../../services/global-constants';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  hideMenuOnClick: boolean = false;
  userToken$: Observable<NbAuthToken>;
  user: IUser;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,
              private router: Router,
              private breakpointService: NbMediaBreakpointsService,
              private userService: UserService) {
                this.menuService.onItemClick().subscribe((event) => {
                  if (event.item.title === 'Log out') {
                    this.router.navigate(['auth/logout']);
                  }
                });
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    this.userService.getCurrentUser().subscribe((user) => {
      this.user = user;
      GlobalConstants.flagAdmin = user.flagAdmin;
    })
    const { xl } = this.breakpointService.getBreakpointsMap();
    const {is} = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
    .pipe(
      map(([, currentBreakpoint]) => currentBreakpoint),
      takeUntil(this.destroy$),
    )
    .subscribe(currentBreakpoint => {
      this.userPictureOnly = currentBreakpoint.width < xl;
      this.hideMenuOnClick = currentBreakpoint.width <= is;
    });
    this.menuService.onItemClick().subscribe(() => {
      if (this.hideMenuOnClick) {
        this.sidebarService.collapse('menu-sidebar');
      }
    });

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
