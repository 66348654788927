import { Injectable } from '@angular/core';
import { ISettings } from '../database/app/app.service';

import algoliasearch from 'algoliasearch/lite';
import { SearchClient } from 'algoliasearch/lite';
import { IUser } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  client: SearchClient;
  constructor() { }

  init(settings: ISettings) {
    this.client = algoliasearch(settings.algoliaAppId, settings.algoliaApiKey);
  }

  searchUser(searchString: string, eventId: string) {
    const index = this.client.initIndex('swipedrinks_users');
    return index
    .search(searchString, {
      hitsPerPage: 50,
      //filters: `event_id:${eventId}`
    });
  }
}
